<template>
  <CFooter class="footerNormal">
    <div class="ms-auto">
      <span class="me-1 text-muted">Versión de sistema {{version}}</span>
    </div>
  </CFooter>
</template>

<script>
import {config} from "/src/config";
import {mapGetters, mapMutations} from "vuex";
import toolbox from "@/toolbox";

export default {
  name: 'AppFooter',
    data() {
        return {
            version: ''
        };
    },
    mounted() {
        this.version = config.appVersion;
    },
    computed: {
        ...mapGetters({
            disableSidebar: 'disableSidebar',
        })
    },
    methods: {
    }
}
</script>
