<template>
    <CHeader position="sticky">
        <CContainer fluid class="headerContainer">
            <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')" v-if="!disableSidebar">
                <i class="fas fa-ellipsis-vertical px-2"></i>
            </CHeaderToggler>
            <div class="ps-1" v-if="disableSidebar">
                <img src="../assets/images/logo.png" style="max-width: 120px; margin-left: 0.5em; padding-top: 0.3em">
            </div>
            <CHeaderBrand class="mx-auto d-lg-none" to="/">
                <img src="../assets/images/logo.png">
            </CHeaderBrand>
            <CHeaderNav class="d-none d-md-flex me-auto">
                <!--<CNavItem>
                    <CNavLink href="/dashboard"> Dashboard</CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink href="#">Users</CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink href="#">Settings</CNavLink>
                </CNavItem>-->
            </CHeaderNav>
            <CHeaderNav>
                <!--<CNavItem>
                    <CNavLink href="#">
                        <CIcon class="mx-2" icon="cil-bell" size="lg"/>
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink href="#">
                        <CIcon class="mx-2" icon="cil-list" size="lg"/>
                    </CNavLink>
                </CNavItem>-->
                <div class="usernameInfo me-5">
                    {{ authInfo.name }} ({{ authInfo.username }})
                </div>
                <CNavItem v-if="!disableSidebar">
                    <CNavLink @click="showStartTaskModal = true; getPanel()" class="text-white me-2">
                        <i class="fas fa-check-double fa-lg me-2"></i><span class="d-none d-sm-inline">Iniciar tarea</span>
                    </CNavLink>
                </CNavItem>
                <!--<CNavItem>
                    <CNavLink href="#">
                        <i class="far fa-bell fa-lg"></i>
                    </CNavLink>
                </CNavItem>-->
                <div v-if="disableSidebar" class="btn ms-2 d-none d-sm-block text-light" style="font-size: 1em" @click="fullScreen">
                    <i :class="{'fas fa-expand me-1': !isFullScreenEnabled, 'fas fa-compress me-1': isFullScreenEnabled}"/> Pantalla completa
                </div>
                <button v-if="!disableSidebar" class="btn btn-outline-light ms-3" @click="logout">
                    <span class="d-none d-sm-inline-block"></span>
                    <i class="fas fa-lock"/>
                </button>
                <button v-if="disableSidebar" class="btn btn-light text-primary ms-4 me-1" @click="outFromEditor" style="font-size: 1em"><i class="fas fa-door-open px-2 text-primary"></i> Salir del editor</button>
            </CHeaderNav>
        </CContainer>
        <div class="globalModal" v-if="showStartTaskModal">
            <div class="globalModalContainer p-3" style="max-width: 450px">
                <div @click="showStartTaskModal = false" class="globalModalClose mt-3"><i class="fas fa-times-circle"></i></div>
                <div>
                    <h5>Selecciona un flujo</h5>
                </div>
                <hr>
                <div class="mt-2">
                    <div v-for="(producto, indexP) in productos" class="panelProductoItem">
                        <div class="row">
                            <div class="col-12 col-sm-9 text-center">
                                <div>
                                    <img v-if="producto.i !== null" :src="producto.i || ''" style="max-width: 100px"/>
                                </div>
                                <div class="text-muted mt-2">
                                    {{ producto.n }}
                                </div>
                            </div>
                            <div class="col-12 col-sm-3 text-center">
                                <button class="btn btn-primary btn-sm ms-2" @click="goToFlujo(producto.l)">
                                    Ver flujo
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import {logo} from '@/assets/brand/logo'
import {mapGetters, mapMutations} from "vuex";
import toolbox from "@/toolbox";
import dayjs from "dayjs";
import Button from "@/views/forms/form_elements/FormElementButton.vue";
import store from "@/store";

export default {
    name: 'AppHeader',
    components: {
        Button,
        AppBreadcrumb,
        AppHeaderDropdownAccnt,
    },
    setup() {
        return {
            logo,
        }
    },
    computed: {
        ...mapGetters({
            loading: 'loading',
            authInfo: 'authInfo',
            disableSidebar: 'disableSidebar',
        })
    },
    data() {
        return {
            showStartTaskModal: false,
            productos: {},
            isFullScreenEnabled: false,
        };
    },
    methods: {
        ...mapMutations(["authSetInfo"]),
        logout() {

            const self = this;
            toolbox.doAjax('POST', 'auth/logout', {},
                function (response) {
                    self.authSetInfo({});
                    self.$router.push('/login');
                },
                function (response) {
                    toolbox.alert(response.msg, 'danger');
                })

            //loginClose

        },
        help() {

            const self = this;
            self.$router.push('/help');
        },
        fullScreen() {
            //$('body').toggleClass('fullscreen-enable');
            if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) { // current working methods
                if (document.documentElement.requestFullscreen) {
                    this.isFullScreenEnabled = true;
                    document.documentElement.requestFullscreen();
                }
                else if (document.documentElement.mozRequestFullScreen) {
                    this.isFullScreenEnabled = true;
                    document.documentElement.mozRequestFullScreen();
                }
                else if (document.documentElement.webkitRequestFullscreen) {
                    this.isFullScreenEnabled = true;
                    document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            }
            else {
                if (document.cancelFullScreen) {
                    this.isFullScreenEnabled = false;
                    document.cancelFullScreen();
                }
                else if (document.mozCancelFullScreen) {
                    this.isFullScreenEnabled = false;
                    document.mozCancelFullScreen();
                }
                else if (document.webkitCancelFullScreen) {
                    this.isFullScreenEnabled = false;
                    document.webkitCancelFullScreen();
                }
            }
        },
        getPanel() {
            const self = this;
            toolbox.doAjax('POST', 'productos/get_panel', {}, function (response) {
                if (response.status) {
                    self.productos = response.data;
                }
            }, function (response) {
                self.msg = response.msg;
            })
        },
        goToFlujo(link) {
            this.showStartTaskModal = false;
            this.$router.push({ path: link }).then(() => { this.$router.go(0) })
        },
        outFromEditor() {
            store.dispatch('disableSidebar', false);
            this.$router.push('/flow-editor');
        }
    }
}
</script>
